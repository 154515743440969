.projects-container {
  margin: 0 10px 0 10px !important;
  padding: 20px;
}

.projects-title h3 {
  font-family: "Archivo", sans-serif;
  font-weight: 800;
  font-size: 50px;
  margin: 0px;
}

.projects-title h4 {
  font-family: "Manrope", sans-serif;
  font-size: 20px;
}

.projects-wrapper {
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 0;
}

.project-card {
  width: 100%;
  background-color: var(--third-color);
  border-radius: 10px; /* Increased border radius for a modern look */
  padding-bottom: 16px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.project-card img {
  width: 100% !important;
  padding: 16px 16px 0px 16px;
  border-radius: 10px 10px 0 0; /* Rounded top corners */
}

.project-card .imgsec {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 250px;
}

.project-card .imgsec img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and prevent distortion */
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 0; /* Rounded top corners */
  background-color: #fff;
}

.project-card .project-content {
  padding: 0 16px 0 16px;
}

.project-content {
  margin-top: 10px;
}

.project-card .project-content h3 {
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  font-size: 24px;
  cursor: default;
}

.project-card .project-content p {
  font-family: "Manrope", sans-serif;
  cursor: default;
  color: #333;
}

a.btn-website,
a.btn-github,
a.btn-maintenence {
  font-family: "Manrope", sans-serif;
  text-decoration: none;
  color: var(--fourth-color);
  padding: 6px 8px 6px 8px;
  border-radius: 5px; /* Increased border radius for a modern look */
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

a.btn-website:hover,
a.btn-github:hover,
a.btn-maintenence:hover {
  background-color: var(--five-color);
  color: var(--six-color);
}

a.btn-maintenence {
  background-color: var(--five-color);
  color: #000;
  margin-right: 5px;
  pointer-events: none;
  opacity: 0.8;
}

a.btn-website {
  margin-right: 6px;
  background-color: var(--six-color);
}

a.btn-github {
  background-color: #000;
}

.project-icon {
  font-size: 20px;
  padding-bottom: 3px;
}

.tech-stack-wrapper {
  width: 100%;
  height: auto;
  margin-top: 16px;
}

span#tech-stack {
  background-color: var(--first-color);
  font-family: "Manrope", sans-serif;
  color: var(--fourth-color);
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
  padding: 4px 8px 4px 8px;
  border-radius: 5px; /* Increased border radius for a modern look */
}

#portfolio {
  height: 100vh;
  overflow: scroll;
  padding: 10vh 0px;
}

.imgsec {
  height: 40vh;
  margin-bottom: 5vh;
  overflow: hidden;
  position: relative;
}

.project-card img .dif-image-project {
  width: 100px;
  height: 40vh;
}

.custom-margin-bottom {
  margin-bottom: 50px;
}

@keyframes portfolioani {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media (max-width: 1200px) {
  .col.project-col {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .projects-container {
    margin: 0 !important;
  }

  .projects-title {
    padding-left: 10px;
  }

  .projects-title h3 {
    font-size: 40px;
  }

  .projects-title h4 {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .project-card img {
    width: 300px !important;
  }
}