.home-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    margin: 0 0 0 30px !important;
}

.home-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -90px;
}

.home-right {
    display: flex;
    align-items: center;
}

.home-left, .home-right {
    padding-left: 30px;
    padding-right: 30px;
}

.home-left h2 {
    font-family: 'Archivo', sans-serif; 
    font-weight: 800;
    font-size: 65px;
    line-height: 70px;
    cursor: default;
    padding-bottom: 20px;
}

.home-left h3 {
    font-family: 'Manrope', sans-serif;
    font-weight: 200;
    font-size: 24px;
    cursor: default;
    padding-bottom: 20px;
}

.home-left h2 span.name-hover:hover {
    -webkit-text-fill-color: var(--fourth-color);
    -webkit-text-stroke-color: #000;
    -webkit-text-stroke-width: 1px;
}

.home-subtitle-container {
    height: 60px; /* Set a fixed height for the subtitle container */
    display: flex;
    align-items: center;
}

.home-subtitle {
    display: inline-block;
    margin-right: 0.5rem;
}

img.home-image {
    width: 550px;
}

.btn-connect {
    width: 200px;
    padding: 12px 8px;
    margin-top: 20px;
    border-radius: 25px; /* Changed to curved edges */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for modern look */
    text-decoration: none;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    color: var(--fourth-color);
    background-color: var(--first-color);
    transition: 0.3s ease; /* Smooth transition */
}

.btn-connect:hover {
    color: var(--first-color);
    background-color: var(--third-color);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}

@media (max-width: 1170px) {
    .home-wrapper {
        margin: 0 !important;
    }
}

@media (max-width: 992px) {
    .home-wrapper {
        margin: 80px 0 80px 0 !important;
        text-align: center;
        display: block;
    }

    .home-left, .home-right {
        padding-left: 24px;
        padding-right: 24px;
    }

    .home-left h2 {
        font-size: 50px;
        margin-bottom: 14px;
    }

    .home-right {
        display: none !important;
    }

    a.btn-connect {
        margin: auto;
    }

    .display-linebreak {
        white-space: pre-line;
    }
}

@media (max-width: 576px) {
    .home-left h2 {
        font-size: 40px;
        line-height: 60px;
    }

    .home-left h3 {
        font-size: 16.4px;
        margin-bottom: 20px;
    }
}

@media (min-width: 1171px) {
    img.home-image {
        width: 650px;
    }
}