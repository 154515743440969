/* Archivo */
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Manrope */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

/* Roboto Mono */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

/* Source Serif Pro */
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

/* Great Vibes */
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

:root {
  --first-color: #116A7B;
  --second-color: #116A7B;
  --third-color: #f0f0f0; /* Updated to a modern light gray */
  --fourth-color: #ffffff; /* Changed to white for a clean look */
  --five-color: #e1c16e;
  --six-color: #116A7B;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--fourth-color);
  color: #333;
  font-family: 'Manrope', sans-serif;
  line-height: 1.6;
}

.preload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--fourth-color);
  height: 100vh;
  width: 100%;
}

.preload h1 {
  font-family: 'Source Serif Pro', serif;
  margin: 0;
  cursor: default;
  color: #333;
}