.about-wrapper {
  display: flex;
  justify-content: space-between;
  height: 80%;
  padding: 0 !important;
}

.about-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 300px;
}

.about-right {
  width: 750px;  
  padding: bottom 100px;
}

.about-left h3,
.about-left h4 {
  cursor: default;
}

.about-left h3 {
  font-family: "Archivo", sans-serif;
  font-weight: 800;
  font-size: 50px;
  margin: 0px;
}

.about-left h4 {
  font-family: "Manrope", sans-serif;
  font-size: 20px;
}

.about-right p {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  margin-bottom: 9px;
  cursor: default;

}

.about-right h4 {
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  line-height: 12px;
  margin: 0;
  cursor: default;
}


.dif-image img {
  border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 8px;
}
@media (max-width: 1400px) {
  .about-wrapper {
    margin: 0 20px 0 20px !important;
  }
}

@media (max-width: 1200px) {
  .about-wrapper {
    margin: 0 20px 0 20px !important;
    flex-direction: column !important;
    justify-content: start;
  }

  .about-left {
    align-items: flex-start;
  }

  .about-right {
    width: 100%;
  }
}

@media (max-width: 992px) {

  .about-left h3 {
    font-size: 40px;
  }

  .about-left h4 {
    font-size: 16px;
  }

  .about-right p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .about-right p {
    font-size: 16px;
    padding-bottom: 150px;
  }
}

@media (max-width: 576px) {
  .about-right p {
    font-size: 16px;
  }

}
