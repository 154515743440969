footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding: 16px 30px;
    background-color: var(--fourth-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.foot-left, .foot-right {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.foot-left p {
    margin: 0;
    cursor: default;
}

.foot-right a {
    color: #333;
    text-decoration: none;
    padding: 8px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.foot-right a:hover {
    color: var(--second-color);
}

.foot-icon {
    font-size: 20px;
    margin-right: 8px;
}

@media (max-width: 992px) {
    footer {
        flex-direction: column;
        padding: 16px;
    }

    .foot-left, .foot-right {
        justify-content: center;
        margin-bottom: 8px;
    }

    .foot-right a {
        margin: 0 8px;
    }
}